<template>
	<div>
		<app-app-header
			:title="props.title"
			v-model="indexStore.isHeaderVisible"
			:color="props.color ?? $config.public.colors.primary"
			:item-link="props.itemLink"
			hide-on-scroll/>

		<app-content>
			<app-header-toolbar :on-top="!indexStore.isHeaderVisible" :color="props.color ?? $config.public.colors.primary" :item-link="props.itemLink">
				<form-input name="search"
							v-model="filterData.search"
							:icon="icons.search"
							type="search"
							enterkeyhint="search"
							hide-details
							inverted
							clearable
							class="flex-grow-x-1"
							@focus="handleSearchInputFocus"
							@blur="handleSearchInputBlur"
							:placeholder="t('placeholder.search')"/>

				<template #tabs>
					<tabs grow>
						<tab v-for="(tab, index) in viewTabs"
							 :key="`tab-${tab.value}-${index}`"
							 :to="{path: route.path, query: {view: tab.value, search: filterData.search}}"
							 :color="props.color ?? $config.public.colors.primary"
							 :text="tab.text" exact replace/>
					</tabs>
				</template>

			</app-header-toolbar>

			<page-content background xs-full sm-full md-full lg-full>
				<container xs-full sm-full class="stretch-layout">
					<template v-if="$auth.loggedIn">
						<event-assignments-list :url="props.url"
												:filter="filterData"
												requires-auth
												has-views
												ref="assignmentsListComponent"
												:details-path-resolver="event => {return {path: `/my/events/${event._key}`, query: isCommunityView ? {view: 'community'} : {}}}"
												@filtering="handleApplyingFilter" class="mt-8">

							<template v-if="$slots.assignment_skeletons" #assignment_skeletons>
								<slot name="assignment_skeletons" v-bind:isCommunityView="isCommunityView"/>
							</template>

							<template #missing>
								<feature-card v-if="isCommunityView" :title="props.communityFeatureTitle"
											  :description="props.communityFeatureDescription"
											  :image="props.featureImage" auth-required class="flex-grow-1 mt-8 sm:mt-16">
									<template #buttons>
										<btn to="/athletes" color="success">
											<icon :icon="icons.search"/>
											<span>{{ t('action.user.search') }}</span>
										</btn>
									</template>
								</feature-card>
								<feature-card v-else :title="props.featureTitle"
											  :description="props.featureDescription"
											  :image="props.featureImage" auth-required class="flex-grow-1 mt-8 sm:mt-16">
									<template #buttons>
										<btn :to="{path: '/my/events', query: {view: 'past'}}"
											 :color="props.color ?? $config.public.colors.primary" outlined>
											<span>{{ t('action.show_all_my_events') }}</span>
										</btn>
										<btn v-if="props.indexItemLink" :to="props.indexItemLink.to" :color="props.color ?? $config.public.colors.primary">
											<span>{{ props.indexItemLink.text }}</span>
										</btn>
										<slot name="missing_buttons"/>
									</template>
								</feature-card>
							</template>

							<template #missingsearch>
								<lazy-missing-info :icon="icons.event"
												   card xs-tile sm-tile
												   class="flex-grow-1 mt-8 sm:mt-16"
												   :text="t('missing.search')">
									<template #button>
										<btn v-if="!isCommunityView" :to="{path: '/my/events', query: {view: 'past', search: filterData.search}}"
											 :color="props.color ?? $config.public.colors.primary" outlined>
											<span>{{ t('action.show_all_my_events') }}</span>
										</btn>
										<btn v-if="props.indexItemLink" :to="{path: props.indexItemLink.to, query: {search: filterData.search}}"
											 :color="props.color ?? $config.public.colors.primary" >
											<icon :icon="icons.search"/>
											<span>{{ props.indexItemLink.text }}</span>
										</btn>
									</template>
								</lazy-missing-info>
							</template>
						</event-assignments-list>

						<slot v-bind:isCommunityView="isCommunityView"/>
					</template>
					<feature-card v-else-if="isCommunityView" :title="props.communityFeatureTitle"
								  :description="props.communityFeatureDescription"
								  :image="props.featureImage" auth-required class="flex-grow-1 mt-8 sm:mt-16">
						<template #buttons>
							<btn to="/athletes">
								<icon :icon="icons.search"/>
								<span>{{ t('action.user.search') }}</span>
							</btn>
						</template>
					</feature-card>
					<feature-card v-else
								  :title="props.featureTitle"
								  :description="props.featureDescription"
								  :image="props.featureImage" auth-required class="flex-grow-1 mt-8 sm:mt-16">
						<template v-if="props.indexItemLink" #buttons>
							<btn :to="props.indexItemLink.to">
								<span>{{ props.indexItemLink.text }}</span>
							</btn>
						</template>
					</feature-card>
				</container>
			</page-content>

		</app-content>
	</div>
</template>

<script setup lang="ts">
import {mdiCalendarMultiple} from '@mdi/js';
import {searchIcon} from "@spoferan/nuxt-spoferan/icons";
import {useIndexStore} from "../../store";

const indexStore = useIndexStore();
const {t} = useI18n();
const route = useRoute();

const props = defineProps<{
	title: string,
	url: string,
	myTabTitle: string,
	featureTitle: string,
	communityFeatureTitle: string,
	featureDescription: string,
	communityFeatureDescription: string,
	featureImage: string,
	itemLink?: object,
	indexItemLink?: object,
	color?: string
}>();

const {
	handleApplyingFilter,
	handleSearchInputFocus,
	handleSearchInputBlur
} = useSearchPage();

const viewTabs = [
	{text: props.myTabTitle, value: undefined},
	{text: t('label.community'), value: 'community'},
];

const filterData = ref({
	search: route.query.search,
	view: route.query.view ?? viewTabs[0].value
});

watch(() => route.query.view, (newValue) => {
	filterData.value.view = newValue ?? viewTabs[0].value;
});

const isCommunityView = computed(() => {
	return filterData.value.view === 'community'
});

const icons = {
	event: mdiCalendarMultiple,
	search: searchIcon,
};

const assignmentsListComponent = ref(null);
function refresh() {
	assignmentsListComponent.value.refresh();
}

defineExpose({
	refresh
});

</script>

